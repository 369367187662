@import "~app/mixins";

.dashboardSearchItem {
  width: 100%;
  padding: 8px 6px;
  margin-bottom: 10px;
  border-width: 0;
  background: $color-white;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
  display: flex;
  height: 58px;
  align-items: center;
  font-family: $font-inter;
  cursor: pointer;

  &[disabled] {
    background: $color-grey-lighter;
  }

  &:not([disabled]) {

    &.active,
    &:focus {
      box-shadow: 0 0 4px 1px $color-teal;
    }
    &:hover {
      background-color: #E2F7FA;
    }
    &.active,
    &:active{
      background-color: $color-teal;
      .value {
        .valueTxt {
          color: $color-white !important;
        }

        .valueIcon {
          svg {
            fill: $color-white;
          }
        }
      }
      .label {
        color: $color-white;
      }
      .svgIcon path {
        fill: #fff;
      }
    }

    .valueTxt {
      color: $color-grey !important;
      text-align: initial;
    }

    cursor: pointer;
  }

  > * {
    max-width: 100%;
  }

  > div {
    width: 100%;
  }

  .searchInner {
    .value {
      display: flex;
      align-items: center;
      .valueIcon {
        fill: $color-teal !important;
        width: 27px;
        height: 27px;
        flex-shrink: 0;
        margin-right: 8px;

        .svgIcon{
          svg{
            width: 100%;
            height: 100%;
          }
        }
        img{
          width: 100%;
        }
      }
      .valueText {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 4px;

        .valueTxt {
          color: $color-grey !important;
          font-size: 22px;
          font-weight: 600;
          text-align: left;
          font-family: Inter;
          line-height: normal;
          margin-bottom: 4px;
        }
        .label {
          font-family: Inter;
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-top: 4px;
        }
      }
    }

    .label {
      color: $color-grey;
      font-size: 8px;
      font-weight: 600;    
      text-align: initial;
    }
    .largeText {
      font-size: 7px !important;
    }
  }
}

.loading {
  > div:first-child {
    > div {
      > div:first-child {
        height: 35px;

        svg {
          width: 40px;
          height: 35px;
        }
      }
    }
  }
} 
 
  @media (min-width: 1200px) {
    body{
      .dashboardSearchItem { 
        .searchInner {
          .value {
            .valueIcon { 
              width: 27px;
              height: 27px; 
            }
            .valueTxt { 
              font-size: 22px;    
              font-family: $font-inter;
              font-weight: 600;
              margin-bottom: 4px;
            }
          }
      
          .label { 
            font-size: 10px;
            font-family: $font-inter;
            margin-top: 4px;
          }
        }
      }
    }
  }

@media only screen and (max-width: 1280px) and (min-width: 1024px) {
  body{
    .dashboardSearchItem { 
      .searchInner {
        .value {
          .valueIcon { 
            width: 23px;
            height: 23px; 
          }
          .valueTxt { 
            font-size: 22px; 
            margin-bottom: 4px;
          }
        }
    
        .label { 
          text-align: initial;
          font-size: 11px; 
          margin-top: 4px;
        }

      }
    }
  }
}
@media only screen and (max-width: 1366px) and (min-width: 1024px) {
  body {
    .dashboardSearchItem {
      .searchInner {
        .label {
          font-size: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 855px) {
  body{
    .dashboardSearchItem { 
      .searchInner {
        .value {
          .valueIcon { 
            width: 18px;
            height: 18px;
              
          }
          .valueTxt { 
            font-size: 18px; 
            margin-bottom: 4px;
          }
        }
    
        .label { 
          font-size: 10px;
          font-weight: 500;
          margin-top: 4px;
        }
      }
    }
  }
}
